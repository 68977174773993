
.headingXl {
  font-size: 2rem;
  line-height: 1.3;
  font-weight: 800;
  letter-spacing: 0.05rem;
  margin: 1rem 0;
}

.lightText {
  color: #666;
}